import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {catchError} from "rxjs/operators";
import {BaseService} from "../common/base.service";
import {Stock} from "./stock";

@Injectable({
  providedIn: 'root'
})
export class StockService extends BaseService {

  private apiUrl = this.baseUrl + '/stock';

  findByLocation(location: string): Observable<Stock[]> {
    return this.http.get<Stock[]>(this.apiUrl + '/location/search?terms=' + encodeURIComponent(location))
      .pipe(catchError(this.handleError('ophalen van voorraadpartij aan de hand van bednummer', [])));
  }

  updateStock(stock: Stock): Observable<Stock> {
    return this.http.put<Stock>(this.apiUrl + '/' + stock.id, stock);
  }

}
