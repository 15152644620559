import {Injectable} from '@angular/core';
import {BaseService} from '../../common/base.service';
import {Observable} from 'rxjs';
import {catchError, mapTo} from 'rxjs/operators';
import {PlugStock} from './plugStock';

@Injectable({
  providedIn: 'root'
})
export class PlugStockService extends BaseService {

  private url = this.baseUrl + '/stek-items';

  findByCodeOrName(searchTerm: string): Observable<PlugStock[]> {
    return this.http.get<PlugStock[]>(this.url + '/search?terms=' + encodeURIComponent(searchTerm))
      .pipe(catchError(this.handleError('zoeken van stek partijen', <PlugStock[]>[] ,
        'Fout bij ophalen van voorraad waarbij zoekterm ' + searchTerm + ' deel uitmaakt van naam of code')));
  }

  getById(id: number): Observable<PlugStock> {
    return this.http.get<PlugStock>(this.url + '/' + id)
      .pipe(catchError(this.handleError('ophalen van stek partijen', undefined, 'Fout bij ophalen van voorraad met id ' + id)));
  }

  findByLocation(location: string): Observable<PlugStock[]> {
    return this.http.get<PlugStock[]>(this.url + '/location/search?terms=' + encodeURIComponent(location))
      .pipe(catchError(this.handleError('ophalen van pot-voorraadpartij aan de hand van bednummer', [])));
  }

  getBedNrs(): Observable<string[]> {
    return this.http.get<string[]>(this.url + '/locations')
      .pipe(catchError(this.handleError('ophalen van bednummers', [])));
  }

  getAll(): Observable<PlugStock[]> {
    return this.http.get<PlugStock[]>(this.url)
      .pipe(catchError(this.handleError('ophalen van alle stek partijenen', [])));
  }

  update(stek: PlugStock): Observable<PlugStock> {
    return this.http.put<PlugStock>(this.url + '/' + stek.id, stek)
      .pipe(catchError(this.handleError('opslaan van stek partij', undefined)));
  }

  delete(stek: PlugStock): Observable<void> {
    return this.http.delete(this.url + '/' + stek.id)
      .pipe(
        mapTo(void 0),
      );
  }

  countLocation(location: string): Observable<PlugStock[]> {
    return this.http.put<PlugStock[]>(
      `${this.baseUrl}/stock/location/${location}`,
      {}
    );
  }

  createPlugStock(stock: PlugStock): Observable<PlugStock> {
    return this.http.post<PlugStock>(this.url, stock);
  }

}
