import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {BehaviorSubject, Observable} from 'rxjs';
import {PotStock} from "../pot/pot";
import {StockService} from "../stock.service";

@Component({
  selector: 'move-stock-dialog',
  templateUrl: './move-stock-dialog.html',
  styleUrls: ['./move-stock-dialog.scss']
})
export class MoveStockDialog implements OnInit {

  plantName: string;
  amount: number;
  fromBednr: string;
  toBednr: string;
  errorMessage: string;
  progressBarValue = 0;
  amountSaved = 0;
  toMove: PotStock[];
  failed: PotStock[];

  savingSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  saving$: Observable<boolean> = this.savingSubject.asObservable();

  constructor(
    public dialogRef: MatDialogRef<MoveStockDialog, boolean>,
    @Inject(MAT_DIALOG_DATA) public data: [PotStock[], string],
    private stockService: StockService
  ) {
    let stocks = data[0];
    this.amount = stocks.length;
    this.toBednr = data[1];
    this.toMove = [ ...stocks ];
    this.plantName = stocks[0].plantName;
    this.fromBednr = stocks[0].location;
  }

  ngOnInit() {
  }

  async answerYes() {
    this.errorMessage = undefined;
    this.savingSubject.next(true);

    await this.moveAll(this.toMove);

    if (this.failed.length === 0) {
      this.dialogRef.close(true);
    } else {
      this.errorMessage = `Kon ${this.failed.length} regels niet opslaan door een serverfout`;
      this.toMove = this.failed;
    }

    this.savingSubject.next(false);
  }

  private moveAll(stock: PotStock[]): Promise<any[]> {
    this.failed = [];
    return Promise.all(stock.map(v => {
      v.location = this.data[1];
      return this.stockService.updateStock(v).toPromise()
        .catch(_ => this.failed.push(v))
        .then(_ => this.progressBarValue = ++this.amountSaved / this.amount * 100);
    }));
  }

  answerNo() {
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(false);
  }

}
