import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, Params} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {PotService} from '../pot.service';
import {PotStock} from '../pot';
import {VoorraadDeleteDialogComponent} from './delete-dialog/voorraad-delete-dialog.component';
import {DeleteDialogResult} from './delete-dialog/DeleteDialogResult';
import {ChangeVrijDialogComponent} from './change-vrij-dialog/change-vrij-dialog.component';
import {OptionsVrijDialogComponent} from './options-vrij-dialog/options-vrij-dialog.component';
import {SplitDialogComponent} from './split-dialog/split-dialog.component';
import {HasReservationsDialogComponent} from './has-reservations-dialog/has-reservations-dialog.component';
import {UnitSize} from '../../../common/unitsize-autocomplete-field/unitsize';
import {MoveStockDialog} from "../../move-stock-dialog/move-stock-dialog";
import {StockService} from "../../stock.service";
import {Stock} from "../../stock";

@Component({
  selector: 'app-pot-detail',
  templateUrl: './pot-detail.component.html',
  styleUrls: ['./pot-detail.component.scss']
})
export class PotDetailComponent implements OnInit {

  private readonly googleSearchUrl = 'https://www.google.com/search?tbm=isch&q=';

  item: PotStock;
  displayedColumns: ['naam', 'reserved'];
  loadingSubject = new BehaviorSubject<boolean>(true);
  isLoading$: Observable<boolean> = this.loadingSubject.asObservable();
  error: any;

  originalLocation: string;
  originalTrashed: number;
  originalDead: number;

  constructor(
    private potService: PotService,
    private stockService: StockService,
    private route: ActivatedRoute,
    private _location: Location,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {
  }

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      const key: number = +params['id'];
      this.potService.getStockById(key)
        .subscribe((v: PotStock) => {
            this.loadingSubject.next(false);
            this.item = v;
            this.originalLocation = v.location;
            this.originalDead = v.dead;
            this.originalTrashed = v.amountTrashed;
          },
          error => {
            this.error = error;
          });
    });
  }

  splitItem() {
    return this.dialog.open(SplitDialogComponent, {data: this.item});
  }

  private async validateAndSaveChanges(): Promise<PotStock> {
    try {
      this.loadingSubject.next(true);
      if (!await this.checkForMoreVoorraadInSameBed()) {
        return Promise.reject();
      }
      if (!await this.checkVrijMoreThanTotal()) {
        return Promise.reject();
      }
      this.setLastCountedDate();
      this.setAskedForCounting();
      return await this.potService.updateStock(this.item).toPromise();
    } catch (error) {
      console.error('Error while saving voorraad', error);
      this.snackBar.open('Kon voorraadpartij niet opslaan', 'sluit', {duration: 2000});
      throw error;
    } finally {
      this.loadingSubject.next(false);
    }
  }

  private async checkForMoreVoorraadInSameBed(): Promise<boolean> {
    if (this.originalLocation !== this.item.location) {
      const moreOfSameVoorraad: Stock[] = (await this.stockService.findByLocation(this.originalLocation).toPromise())
        .filter(v => v.plantCode === this.item.plantCode)
        .filter(v => v.id !== this.item.id);
      if (moreOfSameVoorraad.length > 0) {
        return this.dialog.open(MoveStockDialog, {data: [moreOfSameVoorraad, this.item.location]})
          .afterClosed().toPromise();
      }
    }
    return Promise.resolve(true);
  }

  applyChanges() {
    if ((this.item.dead > this.originalDead && this.item.hasReservations) ||
      (this.item.amountTrashed > this.originalTrashed && this.item.hasReservations)) {
      this.openHasReservationsDialog();
    } else {
      this.validateAndSaveChanges().then(potStock => {
        this.item = potStock;
      })
        .catch(err => {
          console.error(err);
          this.snackBar.open('Kon voorraadpartij niet opslaan', 'sluit', {duration: 2000});
        });
    }
  }

  calculateRestAndTotal(item: PotStock) {
    const originalAmount = item.originalAmount || 0;
    const dead = item.dead || 0;
    const amountTrashed = item.amountTrashed || 0;
    const sold = item.sold || 0;
    const amountReserved = item.amountReserved || 0;
    const amountReservedForProduction = item.amountReservedForProduction || 0;
    item.rest = originalAmount - dead - sold - amountTrashed - amountReserved - amountReservedForProduction;
    item.total = originalAmount - dead - sold - amountTrashed;
  }

  saveAndBack() {
    if ((this.item.dead > this.originalDead && this.item.hasReservations) ||
      (this.item.amountTrashed > this.originalTrashed && this.item.hasReservations)) {
      this.openHasReservationsDialog();
    } else {
      this.validateAndSaveChanges()
        .then(_ => this._location.back());
    }
  }

  private checkVrijMoreThanTotal(): Promise<boolean> {
    if ((this.item.amountFitForSale || 0) <= (this.item.total || 0)) {
      return Promise.resolve(true);
    } else {
      return this.dialog.open(ChangeVrijDialogComponent, {data: this.item})
        .afterClosed().pipe(map(i => !!i)).toPromise();
    }
  }

  searchForImages() {
    window.open(this.googleSearchUrl + this.item.plantName, '_blank');
  }

  cancel() {
    this._location.back();
  }

  openDeleteDialog() {
    if (this.item) {
      this.dialog.open(VoorraadDeleteDialogComponent)
        .afterClosed().toPromise().then((result: DeleteDialogResult) => {
        switch (result) {
          case DeleteDialogResult.DOOD:
            this.registerAllDead();
            break;
          case DeleteDialogResult.UNSELLABLE:
            this.registerAllTrashed();
            break;
        }
      });
    }
  }

  openHasReservationsDialog() {
    this.dialog.open(HasReservationsDialogComponent)
      .afterClosed().toPromise().then(result => {
      if (result) {
        this.validateAndSaveChanges().then(_ => this._location.back());
      }
    });
  }

  openVrijOptions(): void {
    this.dialog.open(OptionsVrijDialogComponent, {
      data: this.item,
      disableClose: true
    });
  }

  private registerAllDead() {
    this.item.dead = (this.item.dead || 0) + this.item.rest;
    this.item.amountFitForSale = 0;
    this.saveAndBack();
  }

  private registerAllTrashed() {
    this.item.amountTrashed = (this.item.amountTrashed || 0) + this.item.rest;
    this.item.amountFitForSale = 0;
    this.saveAndBack();
  }

  private setLastCountedDate() {
    this.item.lastInspection = new Date();
  }

  private setAskedForCounting() {
    this.item.askedToBeCounted = false;
  }

  setUnitSize(unitSize: UnitSize) {
    this.item.unitSizeId = unitSize.id;
    this.item.unitSizeName = unitSize.name;
  }
}
